.store-builder--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}

.store-builder--wrapper {
  align-items: center;
  background-color: white;
  border-radius: 0.41vw;
  display: flex;
  flex-direction: column;
  height: 45.35vw;
  justify-content: space-between;
  margin: 1.41vw auto auto;
  width: 96.3vw;
  z-index: 1;
}

.store-builder--background {
  background-color: #085c71;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.store-builder__store--name {
  align-items: center;
  display: flex;
  height: 5.2vw;
  /*margin-top: 3.18vw;*/
  justify-content: center;
  flex-direction: column;
}

.store-builder__store--name h3 {
  margin-bottom: 1%;
}

.store-builder__store--name p {
  font-size: 0.98vw;
}

.store-builder--body__main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.store-builder__store__products--container {
  display: flex;
  flex-wrap: wrap;
  height: 34.34vw;
  width: 55.2vw;
  justify-content: center;
  overflow: auto;
}

.store-builder__store__shopping-cart--small--text {
  font-size: 1.21vw;
  margin: 1.21vw 0 1.21vw 0;
}

.store-builder__store__shopping-cart--container {
  display: flex;
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  flex-direction: column;
  height: 28.18vw;
  justify-content: space-between;
  margin-bottom: 0.8vw;
  padding: 0 0 0 1.21vw;
  width: 26.91vw;
}

.store-builder__store__shopping-cart--container__list-purchased-products--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.store-builder__store__shopping-cart--container__list-purchased-products--container
  table {
  font-size: 0.95vw;
}

.store-builder__store__shopping-cart--container__list-purchased-products__product--name__cell {
  width: 19.19vw;
}

.store-builder__store__purchase--button {
  align-items: center;
  background: #085c71;
  border-radius: 0.41vw;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 1.21vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 2.97vw;
  justify-content: center;
}

.store-builder__store__delete-icon--container {
  cursor: pointer;
  height: 1.07vw;
  width: 1.07vw;
}

.store-builder__store__delete-icon--container .close-icon {
  height: auto;
}

.store-wic-validation--popup {
  background-color: white;
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  display: flex;
  flex-direction: column;
  height: 39.39vw;
  justify-content: center;
  width: 61.61vw;
  z-index: 2;
  position: absolute;
}

.store-wic-validation--container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .store-wic-validation--popup {
    border: 0.1vw solid #c4c4c4;
    border-radius: 0.41vw;
    height: 72.22vh;
    width: 112.96vh;
  }

  .store-builder--wrapper {
    border-radius: 0.74vh;
    height: 83.14vh;
    margin: 2.59vh auto auto;
    width: 171.85vh;
  }

  .store-builder__store--name {
    height: 9.53vh;
    /*margin-top: 5.83vh;*/
  }

  .store-builder__store__products--container {
    height: 62.96vh;
    width: 101.2vh;
  }

  .store-builder__store__shopping-cart--container {
    height: 51.66vh;
    margin-bottom: 1.48vh;
    padding: 0 0 0 2.22vh;
    width: 49.35vh;
  }

  .store-builder__store__shopping-cart--small--text {
    font-size: 2.22vh;
    margin: 2.22vh 0 2.22vh 0;
  }

  .store-builder__store__purchase--button {
    border-radius: 0.74vh;
    font-size: 2.22vh;
    height: 5.46vh;
  }

  .store-builder__store--name p {
    font-size: 1.75vh;
  }

  .store-builder__store__delete-icon--container {
    cursor: pointer;
    height: 1.96vh;
    width: 1.96vh;
  }

  .store-builder__store__shopping-cart--container__list-purchased-products--container
    table {
    font-size: 1.75vh;
  }

  .store-builder__store__shopping-cart--container__list-purchased-products__product--name__cell {
    width: 350vh;
  }
}

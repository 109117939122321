.transportation-icon-and-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.transportation-icon-and-text.disabled h2,
.transportation-icon-and-text.disabled p {
  color: #c0c0c0;
}

.transportation-icon-and-text h2 {
  color: #535353;
  font-size: 1.5vw;
  margin: 1vw 0;
}

.transportation-icon-and-text p {
  text-align: center;
}

.transportation-icon-and-text:not(:last-child) {
  margin-right: 5vw;
}

.transportation-icon {
  height: 8.28vw;
  width: 8.28vw;
}

.transportation-icon-with-indicator {
  position: relative;
}

.transportation-icon-and-text .checkmark-icon,
.transportation-icon-and-text .close-icon {
  position: absolute;
  right: -1.7vw;
}

.transportation-icon-and-text .close-icon {
  height: 5vw;
  top: -1.5vw;
  width: 5vw;
}

.transportation-icon-and-text .checkmark-icon {
  height: 3.7vw;
  right: -0.9vw;
  top: -0.9vw;
  width: 3.7vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .transportation-icon-and-text h2 {
    font-size: 3vh;
  }

  .transportation-icon-and-text:not(:last-child) {
    margin-right: 8vh;
  }

  .transportation-icon {
    height: 15.18vh;
    width: 15.18vh;
  }

  .transportation-icon-and-text .close-icon {
    height: 10vh;
    right: -4vh;
    top: -3.4vh;
    width: 10vh;
  }

  .transportation-icon-and-text .checkmark-icon {
    height: 7.4vh;
    right: -2.5vh;
    top: -2vh;
    width: 7.4vh;
  }
}

.side-menu--wrapper__close--icon--container {
  right: 0;
  position: absolute;
  top: 0;
  z-index: 3;
}

.slide-menu--background {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.slide-menu__close--icon--container {
  position: absolute;
  top: 2.02vw;
  right: 2.02vw;
  z-index: 2;
}

.slide-menu--wrapper {
  align-items: center;
  background-color: white;
  /*border-radius: 0.41vw;*/
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  z-index: 2;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .slide-menu__close--icon--container {
    top: 3.7vh;
    right: 3.7vh;
  }
}

.transportation-module__close--icon--container {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.25vw;
  padding-top: 0.25vw;
  z-index: 2;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .transportation-module__close--icon--container {
    padding-right: 0.46vw;
    padding-top: 0.46vw;
  }
}

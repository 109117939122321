@font-face {
  font-family: 'RidleyGrotesk Black';
  src: local('RidleyGrotesk Black'),
    url(assets/fonts/RidleyGrotesk-Black.otf) format('opentype');
}

@font-face {
  font-family: 'RidleyGrotesk Bold';
  src: local('RidleyGrotesk Bold'),
    url(assets/fonts/RidleyGrotesk-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'RidleyGrotesk SemiBold';
  src: local('RidleyGrotesk SemiBold'),
    url(assets/fonts/RidleyGrotesk-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'RidleyGrotesk Light';
  src: local('RidleyGrotesk Light'),
    url(assets/fonts/RidleyGrotesk-Light.otf) format('opentype');
}

@font-face {
  font-family: 'RidleyGrotesk Medium';
  src: local('RidleyGrotesk Medium'),
    url(assets/fonts/RidleyGrotesk-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'RidleyGrotesk Regular';
  src: local('RidleyGrotesk Regular'),
    url(assets/fonts/RidleyGrotesk-Regular.otf) format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.point--container {
  display: flex;
}

.point--circle {
  height: 0.6vw;
  margin-left: 0.3vw;
  margin-right: 0.3vw;
  width: 0.6vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .point--circle {
    height: 1.11vh;
    margin-left: 0.55vh;
    margin-right: 0.55vh;
    width: 1.11vh;
  }
}

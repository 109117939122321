.avatar--position__home {
  left: 18.5vw;
  top: 7.5vw;
}

.avatar--position__community-kitchen {
  left: 30.2vw;
  top: 29vw;
}

.avatar--position__corner-store {
  left: 23.6vw;
  top: 41.8vw;
}

.avatar--position__local-neighborhood-bus-stop {
  left: 34vw;
  top: 35.8vw;
}

.avatar--position__food-tiger {
  left: 81.2vw;
  top: 17vw;
}

.avatar--position__food-tiger-bus-stop {
  left: 84.1vw;
  top: 15.3vw;
}

.avatar--position__snap-office {
  left: 43.3vw;
  top: 15.4vw;
}

.avatar--position__vita-services {
  left: 52.4vw;
  top: 8.65vw;
}

.avatar--position__snap-vita-bus-stop {
  left: 48.4vw;
  top: 4.9vw;
}

.avatar--position__food-pantry {
  left: 72.2vw;
  top: 38.1vw;
}

.avatar--position__wic-clinic {
  left: 80.2vw;
  top: 48vw;
}

.avatar--position__pantry-wic-bus-stop {
  left: 75.5vw;
  top: 50.5vw;
}

.avatar--icon {
  position: absolute;
  width: 2.42vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .avatar--position__home {
    left: 33vh;
    top: 13vh;
  }

  .avatar--position__community-kitchen {
    left: 54vh;
    top: 51vh;
  }

  .avatar--position__corner-store {
    left: 42vh;
    top: 74.2vh;
  }

  .avatar--position__local-neighborhood-bus-stop {
    left: 60.2vh;
    top: 63.8vh;
  }

  .avatar--position__food-tiger {
    left: 144.5vh;
    top: 30.1vh;
  }

  .avatar--position__food-tiger-bus-stop {
    left: 149.5vh;
    top: 27.2vh;
  }

  .avatar--position__snap-office {
    left: 76.8vh;
    top: 27.4vh;
  }

  .avatar--position__vita-services {
    left: 93.2vh;
    top: 15.5vh;
  }

  .avatar--position__snap-vita-bus-stop {
    left: 86vh;
    top: 8.6vh;
  }

  .avatar--position__food-pantry {
    left: 128.6vh;
    top: 67.5vh;
  }

  .avatar--position__wic-clinic {
    left: 142.6vh;
    top: 85.37vh;
  }

  .avatar--position__pantry-wic-bus-stop {
    left: 134.3vh;
    top: 90.3vh;
  }

  .avatar--icon {
    position: absolute;
    width: 4.44vh;
  }
}

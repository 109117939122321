.menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5vw;
  border-radius: 3.63vw;
}

.menu--icon--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  cursor: pointer;
  display: flex;
  height: 4.47vw;
  justify-content: center;
  width: 4.47vw;
}

.menu--icon {
  height: 2.7vw;
  width: 2.7vw;
  z-index: 2;
}
.menu--icon--backdrop {
  height: 3.23vw;
  width: 3.23vw;
  position: absolute;
  z-index: 1;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .menu--icon {
    height: 4.8vh;
    width: 4.8vh;
  }

  .menu--icon--backdrop {
    height: 5.92vh;
    width: 5.92vh;
    position: absolute;
    z-index: 1;
  }

  .menu--icon--container {
    border-radius: 6.66vh;
    height: 7.96vh;
    width: 7.96vh;
  }
}

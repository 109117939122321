.board-overview--container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.board-overview--wrapper {
  background-color: white;
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  display: flex;
  flex-direction: column;
  height: 39.39vw;
  justify-content: center;
  z-index: 1;
  width: 61.61vw;
}

.board-overview--wrapper.tour {
  height: 44.39vw;
  margin-bottom: 6vw;
}

.board-overview--wrapper__small {
  height: 31.25vw;
}

.board-overview--body {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 1.5%;
  margin-top: 3%;
}

.board-overview--body.tour {
  margin-top: 4%;
}

.board-overview--body.bus-instructions {
  margin-bottom: 12.5%;
}

.board-overview--screenshot {
  width: 50vw;
}

.board-overview--avatar {
  width: 15vw;
}

.board-overview--transportation-options {
  justify-content: flex-start;
}

.board-overview--transportation-options h1 {
  margin-top: -1%;
  margin-bottom: 7%;
}

.board-overview--background {
  background-color: #e5e5e5;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 60%;
  z-index: 0;
}

.board-overview--body__text-content {
  width: 40.4vw;
}

.board-overview--body__main-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20.2vw;
  justify-content: center;
}
.board-overview--body__main-content.tour {
  gap: 1.7vw;
  height: 32vw;
  position: relative;
}

.board-overview--body__main-content .dashboard__time-check__pod--container,
.board-overview--body__main-content .dashboard__nutrition-check__pod--container,
.board-overview--body__main-content .dashboard__budget-check__pod--container,
.board-overview--body__main-content .dashboard__user-check__pod--container {
  transform: scale(1.2);
  border-width: 3px;
}

.board-overview--body__main-content .fade {
  position: absolute;
  bottom: 2vw;
  width: 100%;
  height: 38%;
  background: rgba(255, 255, 255, 0)
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 80%
    )
    repeat scroll 0 0;
}

.board-overview--preview,
.board-overview--preview.location-module__form--container {
  height: 50%;
  overflow: hidden;
  position: relative;
}

.board-overview--preview.location-module__form--container {
  margin: 0;
  padding: 0;
}

.board-overview__close--icon {
  width: 1.67vw;
}

.board-overview--button--container--tour .button--container--row {
  margin-bottom: 3%;
}

.board-overview--button--container--tour {
  margin-bottom: 1%;
}

.board-overview--button--container--normal-height {
  height: 4.94vw;
  justify-content: space-between;
}

.button--container--row.first-slide {
  margin-bottom: 5%;
}

.board-overview--icon {
  width: 8.28vw;
}

.board-overview--body__budget-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.board-overview--cash-snap-total--container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 33.54vw;
}

.board-overview--wic-bus-ticket--container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 24.39vw;
}

.board-overview--body__budget-content__line {
  height: 2px;
  width: 32.52vw;
  background-color: #dddddd;
}

.board-overview--menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5vw;
  width: 70%;
}

.board-overview--menu--icon-and-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.board-overview--menu--icon-and-text p {
  text-align: center;
  margin-top: 3vw;
}

.board-overview--menu--icon--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  cursor: pointer;
  display: flex;
  height: 4.47vw;
  justify-content: center;
  width: 4.47vw;
}

.board-overview--menu--icon {
  height: 2.7vw;
  width: 2.7vw;
  z-index: 2;
}
.board-overview--menu--icon--backdrop {
  height: 3.23vw;
  width: 3.23vw;
  position: absolute;
  z-index: 1;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .board-overview--wrapper {
    height: 72.22vh;
    width: 112.96vh;
  }

  .board-overview--wrapper.tour {
    height: 80.22vh;
    margin-bottom: 9vh;
  }

  .board-overview--wrapper__small {
    height: 55.55vh;
  }

  .board-overview--body__main-content {
    height: 37.03vh;
  }

  .board-overview--body__main-content.tour {
    gap: 3.2vh;
    height: 56.03vh;
  }

  .board-overview--body__main-content .fade {
    bottom: 0;
  }

  .board-overview--body__text-content {
    width: 74.07vh;
  }

  .board-overview__close--icon {
    width: 3.07vh;
  }

  .board-overview--screenshot {
    width: 93vh;
  }

  .board-overview--avatar {
    width: 29vh;
  }

  .board-overview--button--container--normal-height {
    height: 8.98vh;
    justify-content: space-between;
  }

  .board-overview--icon {
    width: 15.18vh;
  }

  .board-overview--cash-snap-total--container {
    width: 59.62vh;
  }

  .board-overview--wic-bus-ticket--container {
    width: 44.72vh;
  }

  .board-overview--body__budget-content__line {
    width: 59.62vh;
  }
}

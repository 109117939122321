.game-board--container {
  background-color: #14ab69;
  background-image: url('../../assets/images/background.jpg');
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.game-board--place-icon--food-tiger__stroke {
  height: 11.4vw;
  left: 70.9vw;
  position: absolute;
  top: 12vw;
}

.game-board--place-icon--corner-store__stroke {
  height: 11.9vw;
  left: 13.2vw;
  position: absolute;
  top: 36.7vw;
}

.game-board--place-icon--food-pantry__stroke {
  height: 10.45vw;
  left: 62.5vw;
  position: absolute;
  top: 32.34vw;
}

.game-board--place-icon--bus-stop__stroke {
  height: 5.8vw;
  left: 32.15vw;
  position: absolute;
  top: 33.75vw;
}

.game-board--place-icon--food-pantry-wic-clinc-bus-stop__stroke {
  height: 6.1vw;
  left: 70.28vw;
  position: absolute;
  top: 47.67vw;
}

.game-board--place-icon--food-tiger-bus-stop__stroke {
  height: 5.8vw;
  left: 82.45vw;
  position: absolute;
  top: 13.15vw;
}

.game-board--place-icon--snap-vita-bus-stop__stroke {
  height: 5.78vw;
  left: 46.68vw;
  position: absolute;
  top: 2.75vw;
}

.game-board--place-icon--community-kitchen__stroke {
  height: 11.75vw;
  left: 18.05vw;
  position: absolute;
  top: 21.84vw;
}

.game-board--place-icon--home__stroke {
  height: 10.44vw;
  left: 10.371vw;
  position: absolute;
  top: 1.7vw;
}

.game-board--place-icon--snap-office__stroke {
  height: 14.55vw;
  left: 32.22vw;
  position: absolute;
  top: 8.02vw;
}

.game-board--place-icon--wic-clinic__stroke {
  height: 12.89vw;
  left: 74.84vw;
  position: absolute;
  top: 39.91vw;
}

.game-board--place-icon--vita-services__stroke {
  height: 12.38vw;
  left: 52.85vw;
  position: absolute;
  top: 1.95vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .game-board--place-icon--food-tiger__stroke {
    height: 20.25vh;
    left: 126vh;
    top: 21.42vh;
  }

  .game-board--place-icon--corner-store__stroke {
    height: 21.15vh;
    left: 23.63vh;
    top: 65.15vh;
  }

  .game-board--place-icon--food-pantry__stroke {
    height: 18.49vh;
    left: 111.19vh;
    top: 57.5vh;
  }

  .game-board--place-icon--bus-stop__stroke {
    height: 10.3vh;
    left: 57.3vh;
    top: 60vh;
  }

  .game-board--place-icon--food-pantry-wic-clinc-bus-stop__stroke {
    height: 10.85vh;
    left: 124.95vh;
    top: 84.7vh;
  }

  .game-board--place-icon--food-tiger-bus-stop__stroke {
    height: 10.3vh;
    left: 146.53vh;
    top: 23.5vh;
  }

  .game-board--place-icon--snap-vita-bus-stop__stroke {
    height: 10.3vh;
    left: 83.1vh;
    top: 5vh;
  }

  .game-board--place-icon--community-kitchen__stroke {
    height: 20.83vh;
    left: 32.25vh;
    top: 38.91vh;
  }

  .game-board--place-icon--home__stroke {
    height: 18.46vh;
    left: 18.63vh;
    top: 3.25vh;
  }

  .game-board--place-icon--snap-office__stroke {
    height: 25.817vh;
    left: 57.42vh;
    top: 14.32vh;
  }

  .game-board--place-icon--wic-clinic__stroke {
    height: 22.85vh;
    left: 133.14vh;
    top: 71vh;
  }

  .game-board--place-icon--vita-services__stroke {
    height: 22vh;
    left: 94vh;
    top: 3.6vh;
  }
}

.button--icon__container.left {
  justify-content: flex-start;
  padding-left: 0.4vw;
  padding-right: 0;
}

.button--icon__container.left img {
  transform: scaleX(-1);
}

.button--icon__container.left + .button--text__container {
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 0.4vw;
}

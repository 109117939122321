.checkout--container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 2;
}

.checkout--wrapper {
  align-items: center;
  border-radius: 0.41vw;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 1.41vw auto 2.72vw auto;
}

.checkout--wrapper--justify-content--center {
  justify-content: center;
}

.checkout--wrapper--half-height {
  height: 50%;
}

.checkout--snap-cash--container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30.6vw;
}

.button--container--row.checkout-fail {
  margin-bottom: 1.5vw;
}

.checkout--text--container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.checkout--instruction__text {
  font-size: 1.21vw;
  text-align: center;
}

.checkout--title__text {
  text-align: center;
  margin-bottom: 0.7vw;
}
.checkout--balance_title__text {
  color: #d43c28;
}

.checkout--snap-warning__text {
  color: #8c8d8d;
  font-size: 0.95vw;
  margin-bottom: 1.46vw;
  text-align: center;
}

.checkout__payment__input--title {
  color: #085c71;
  font-size: 1.21vw;
  margin-bottom: 0.2vw;
  text-align: start;
}

.checkout__payment__input--available {
  color: #404243;
  font-size: 0.95vw;
  margin-top: 0.8vw;
  text-align: start;
}

.checkout__payment__input {
  font-size: 1.21vw;
  height: 3.13vw;
  width: 14.79vw;
}

.checkout__wic-checkout__warning {
  display: flex;
  flex-direction: column;
  margin: 3vw 0 1vw;
  gap: 1.5vw;
  padding: 0 25vw;
}

.checkout__wic-checkout__warning-text {
  font-size: 1.5vw;
  font-weight: 600;
  text-align: center;
}

.checkout__wic-checkout__warning-subtext {
  color: #404243;
  font-size: 1.21vw;
  text-align: center;
}

.checkout__wic-checkout__warning + .button--container--row button:last-child {
  width: 23.6vw;
}

.checkout__wic-checkout__radio-button--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.96vw;
  width: 30.6vw;
  border-bottom: 0.1vw solid #f1f1f1;
}

.checkout__wic-checkout__radio-button--container.disabled
  .checkout__wic-checkout__radio-button__title {
  color: #9fa0a1;
}

.checkout__wic-checkout__radio-button__icon,
.checkout__wic-checkout__radio-button--container .checkmark-icon {
  height: 1.51vw;
  width: 1.51vw;
}

.checkout__wic-checkout__radio-button__title {
  color: #404243;
  font-size: 0.95vw;
  margin-left: 1vw;
}

.checkout__action-complete__action-message {
  justify-content: center;
  padding-bottom: 15%;
  text-align: center;
  width: 30.3vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .checkout--wrapper {
    margin: 2.59vh auto 5vh auto;
  }

  .checkout--instruction__text {
    font-size: 2.22vh;
  }

  .checkout--title__text,
  .checkout--balance_title__text {
    margin-bottom: 1.29vh;
  }

  .checkout--snap-warning__text {
    font-size: 1.75vh;
    margin-bottom: 2.68vh;
  }

  .checkout--snap-cash--container {
    width: 56.11vh;
  }

  .checkout__payment__input--title {
    font-size: 2.22vh;
    margin-bottom: 0.37vh;
  }

  .checkout__payment__input--available {
    font-size: 1.75vh;
    margin-top: 1.48vh;
  }

  .checkout__payment__input {
    font-size: 2.22vh;
    height: 5.74vh;
    width: 27.12vh;
  }

  .checkout__wic-checkout__warning {
    gap: 3vh;
    margin-top: 6vh 0 2vh;
    padding: 0 50vh;
  }

  .checkout__wic-checkout__warning-subtext {
    font-size: 2.2vh;
  }

  .checkout__wic-checkout__warning-text {
    font-size: 2.5vh;
  }

  .checkout__wic-checkout__warning + .button--container--row button:last-child {
    width: 44.11vh;
  }

  .checkout__wic-checkout__radio-button--container {
    height: 5.44vh;
    width: 56.11vh;
  }

  .checkout__wic-checkout__radio-button__icon,
  .checkout__wic-checkout__radio-button--container .checkmark-icon {
    height: 2.77vh;
    width: 2.77vh;
  }

  .checkout__wic-checkout__radio-button__title {
    font-size: 1.75vh;
    margin-left: 2vh;
  }

  .checkout__action-complete__action-message {
    width: 55.55vh;
  }
}

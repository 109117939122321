body {
  background: black;
}

.container {
  background: white;
  position: relative;
  width: 100vw;
  height: 56.25vw; /* height:width ratio = 9/16 = .5625  */
  max-height: 100vh;
  max-width: 177.78vh; /* 16/9 = 1.778 */
  margin: auto;
}

.button {
  align-items: center;
  background: #20aac0;
  border-radius: 0.5vw;
  color: #242424;
  cursor: pointer;
  display: flex;
  font-family: RidleyGrotesk Regular;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: normal;
  height: 3.5vw;
  justify-content: center;
  margin: 1vw;
  text-decoration: none;
  width: 100%;
}

.button_dark {
  align-items: center;
  background: #085c71;
  border-radius: 0.5vw;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: RidleyGrotesk Regular;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: normal;
  height: 3.5vw;
  justify-content: center;
  margin: 1vw;
  text-decoration: none;
  width: 100%;
}

.button_text_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.link {
  color: #737373;
  cursor: pointer;
  font-family: RidleyGrotesk Regular;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
}

.header {
  align-items: flex-end;
  color: #085c71;
  display: flex;
  font-family: RidleyGrotesk Medium;
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.info {
  color: #535353;
  font-family: RidleyGrotesk Medium;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.arrow_left_short_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 100%;
}

.arrow_left_short_icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 1vw;
  width: 2.3vw;
  height: 1.7vw;
}

.ledger-slide--container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ledger-slide__rectangle {
  background-color: white;
  border-radius: 0.41vw;
  bottom: 0.83vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 54.58vw;
  left: 0.83vw;
  position: absolute;
  width: 54.37vw;
  z-index: 2;
}

.ledger-slide__rectangle--body {
  margin-top: 17%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 28.64vw;
  justify-content: space-between;
}

.ledger-slide__rectangle--body--nutrition {
  margin-top: 9%;
}

.ledger-slide__rectangle--body__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.ledger-slide__rectangle--body__content--nutrition {
  margin-top: 6%;
}

.ledger-slide__rectangle--body__content__budget--container {
  align-items: center;
  display: flex;
  height: 14.79vw;
  justify-content: space-between;
  flex-direction: column;
  width: 43.75vw;
}

.ledger-slide__rectangle--body__content__user-check--container {
  width: 43.75vw;
}

.ledger-slide__rectangle--body__content__budget--cash-snap-total--container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ledger-slide__rectangle--body__content__budget--wic-bus-ticket--container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 65%;
}

.ledger-slide__close--icon--container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .ledger-slide__rectangle {
    border-radius: 0.74vh;
    bottom: 1.48vh;
    height: 97.03vh;
    left: 1.48vh;
    width: 96.66vh;
  }

  .ledger-slide__rectangle--body {
    height: 50.92vh;
  }

  .ledger-slide__rectangle--body__content__budget--container {
    height: 26.29vh;
    width: 77.77vh;
  }

  .ledger-slide__rectangle--body__content__user-check--container {
    width: 77.77vh;
  }

  .ledger-slide__profile--container h1 {
    font-size: 2.22vh;
    margin-bottom: 0.46vh;
  }
}

.home--container {
  background-image: url('../../assets/images/home-background.jpg');
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.home--button--container {
  bottom: 10.23vw;
  left: 34.21vw;
  position: absolute;
}

.home--subText {
  bottom: 15.83vw;
  left: 30.3vw;
  text-align: center;
  width: 39vw;
  position: absolute;
}

.home--info__button--container {
  cursor: pointer;
  height: 1.99vw;
  position: absolute;
  right: 1.44vw;
  top: 1.44vw;
  width: 1.99vw;
}

.home-overview--background {
  background-color: #e5e5e5;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 60%;
  z-index: 1;
}

.home-overview--background--container {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  justify-content: center;
  width: 100%;
}

.home-overview__text--body {
  align-items: center;
  display: flex;
  height: 90%;
  line-height: 2;
  justify-content: center;
  text-align: center;
  width: 80%;
}

.home-overview--body {
  background-color: white;
  border-radius: 0.41vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 40.62vw;
  position: absolute;
  width: 54.37vw;
  z-index: 1;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .home--button--container {
    bottom: 18.25vh;
    left: 60.83vh;
  }

  .home--subText {
    bottom: 28.33vh;
    left: 54.21vh;
    width: 68vh;
  }

  .home--info__button--container {
    height: 3.55vh;
    right: 2.57vh;
    top: 2.57vh;
    width: 3.55vh;
  }

  .home-overview--body {
    border-radius: 0.74vh;
    height: 72.22vh;
    width: 96.66vh;
  }
}

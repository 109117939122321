.close-icon {
  cursor: pointer;
  height: 3.34vw; /*2x*/
  width: 3.34vw; /*2x*/
  z-index: 3;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .close-icon {
    height: 6.14vh; /*2x*/
    width: 6.14vh; /*2x*/
  }
}

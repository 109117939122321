.game-instructions--button--container {
  height: 5.375vw;
  justify-content: space-between;
  margin-bottom: 5vw;
}

.game-instructions--input--name {
  font-size: 1.25vw;
  height: 3.22vw;
  padding-left: 1.04vw;
  width: 100%;
}

.game-instructions--container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.game-instructions--wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

.game-instructions--wrapper .transportation-options {
  margin-bottom: 3vw;
}

.game-instructions--wrapper .transportation-icon-and-text:not(:last-child) {
  margin-right: 10vw;
}

.game-instructions--wrapper .transportation-icon {
  height: 9vw;
  width: 9vw;
}

.game-instructions--max-width {
  width: 54.84vw;
}

.game-instructions--icon {
  width: 8.28vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .game-instructions--button--container {
    height: 11vh;
    margin-bottom: 10vh;
  }

  .game-instructions--input--name {
    font-size: 2.22vh;
    height: 5.74vh;
    padding-left: 1.85vh;
  }

  .game-instructions--max-width {
    width: 100.55vh;
  }

  .game-instructions--wrapper .transportation-options {
    margin-bottom: 6vh;
  }

  .game-instructions--wrapper .transportation-icon-and-text:not(:last-child) {
    margin-right: 20vh;
  }

  .game-instructions--wrapper .transportation-icon {
    height: 18vh;
    width: 18vh;
  }

  .game-instructions--icon {
    width: 15.18vh;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(254, 254, 254, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(254, 254, 254, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(254, 254, 254, 0);
  }
}

.pulse {
  box-shadow: 0 0 0 0 rgba(254, 254, 254, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.opacity {
  opacity: 0.25;
}

.border {
  outline: 3px solid #20aac0;
}

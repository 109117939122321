.post-simulation--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  padding: 0 20vw;
}

.post-simulation--header-container {
  display: flex;
  justify-content: center;
}

.post-simulation--info-container {
  display: flex;
  justify-content: center;
  margin: 4vw 0 5.5vw;
}

.post-simulation--info {
  margin: 2.25vw auto;
  display: flex;
  justify-content: center;
}

.post-simulation--chart-container {
  margin-bottom: 2.2vw;
  display: flex;
  justify-content: center;
}

.post-simulation--health-status-body {
  margin-bottom: 2.25vw;
}

.post-simulation--point-container {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1.25vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .post-simulation--wrapper {
    padding: 0 40vh;
  }

  .post-simulation--info-container {
    margin: 8.33vh 0 10.83vh;
  }

  .post-simulation--info {
    margin: 4.53vh auto;
  }

  .post-simulation--chart-container {
    margin-bottom: 5.46vh;
  }

  .post-simulation--health-status-body {
    margin-bottom: 4.53vh;
  }

  .post-simulation--point-container {
    margin-top: 2.48vh;
  }
}

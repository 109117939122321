.nutrition-chart--table--container {
  /*width: 41.66vw;*/
}

.nutrition-chart--table--progress--container {
  display: flex;
  justify-content: center;
}

.nutrition--instruction__text {
  font-size: 0.93vw;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 1%;
}

.nutrition-chart--table tr {
  color: #737373;
  font-family: RidleyGrotesk Regular, serif;
  font-size: 1.06vw;
  font-style: normal;
  font-weight: normal;
  border-collapse: separate;
}

.nutrition-chart--progress {
  border: none !important;
  height: 1vw !important;
  width: 28.03vw !important;
  background: #f1f1f1 !important;
  border-radius: 4.54vw !important;
}

.nutrition-chart--progress[value]::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 4.5vw;
}

.nutrition-chart--progress::-moz-progress-bar {
  background: #20aac0;
  border-radius: 4.5vw;
}

.nutrition-chart--progress::-webkit-progress-value {
  background: #20aac0;
  border-radius: 4.5vw;
}

.nutrition-chart--progress {
  color: #20aac0;
  border-radius: 4.5vw;
}

.nutrition-chart--progress-max::-moz-progress-bar {
  border-radius: 4.5vw;
  background: #d43c28;
}

.nutrition-chart--progress-max::-webkit-progress-value {
  border-radius: 4.5vw;
  background: #d43c28;
}

.nutrition-chart--progress-max {
  border-radius: 4.5vw;
  background: #d43c28;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .nutrition-chart--table--container {
    /*width: 74.07vh;*/
  }

  .nutrition--instruction__text {
    font-size: 1.66vh;
  }

  .nutrition-chart--table tr {
    font-size: 1.94vh;
  }

  .nutrition-chart--progress {
    height: 1.85vh !important;
    width: 51.38vh !important;
    border-radius: 8.33vh !important;
  }

  .nutrition-chart--progress[value]::-webkit-progress-bar {
    border-radius: 8.33vh;
  }

  .nutrition-chart--progress::-moz-progress-bar {
    border-radius: 8.33vh;
  }

  .nutrition-chart--progress::-webkit-progress-value {
    border-radius: 8.33vh;
  }

  .nutrition-chart--progress {
    border-radius: 4.5vh;
  }
}

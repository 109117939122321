* {
  font-family: RidleyGrotesk Medium, serif;
}

h1 {
  align-items: flex-end;
  color: #085c71;
  display: flex;
  font-size: 3.33vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 110%;
  margin: 0;
}

h2 {
  align-items: flex-end;
  color: #085c71;
  display: flex;
  font-size: 2.29vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 110%;
  margin: 0;
}

h3 {
  align-items: flex-end;
  color: #085c71;
  display: flex;
  font-size: 1.81vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 110%;
  margin: 0;
}

p {
  color: #535353;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

input {
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  box-sizing: border-box;
}

.button {
  align-items: center;
  border-radius: 0.41vw;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.button--normal-width {
  height: 3.2vw;
  width: 31.56vw;
}

.button--small-width {
  height: 3.2vw;
  width: 14.79vw;
}

.button--text__container {
  display: flex;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  justify-content: center;
  padding-left: 0.4vw;
}

.button--text {
  color: #242424;
  font-family: RidleyGrotesk Regular, serif;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.button--text__normal-font-size {
  font-size: 1.25vw;
}

.button--text__small-font-size {
  font-size: 0.8vw;
}

.button--icon__container {
  display: flex;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  justify-content: flex-end;
  padding-right: 0.4vw;
}

.button--icon {
  width: 1.56vw;
}

.link {
  color: #737373;
  cursor: pointer;
  font-family: RidleyGrotesk Regular, serif;
  font-size: 1.25vw;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
}

.button--container--row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button--container--row button:not(:last-child) {
  margin-right: 1.78vh;
}

.button--container--column {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sub-container {
  background: white;
  height: 56.25vw;
  margin: auto;
  max-height: 100vh;
  max-width: 177.78vh;
  position: relative;
  width: 100vw;
}

.container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.center {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-aspect-ratio: 16/9) {
  h1 {
    font-size: 5.92vh;
  }

  h2 {
    font-size: 4.07vh;
  }

  h3 {
    font-size: 3.33vh;
  }

  p {
    font-size: 2.22vh;
  }

  .button {
    border-radius: 3px;
  }

  .button--normal-width {
    height: 5.74vh;
    width: 56.11vh;
  }

  .button--small-width {
    height: 5.74vh;
    width: 27.12vh;
  }

  .button--text {
    font-size: 2.22vh;
  }

  .button--text__small-font-size {
    font-size: 1.48vh;
  }

  .button--icon {
    width: 2.77vh;
  }

  .link {
    font-size: 2.22vh;
  }
}

.dashboard--container {
  position: absolute;
  left: 1.71vw;
  bottom: 1.66vw;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 67.7vw;
}

.dashboard__pod__icon {
  height: 3.23vw;
  width: 3.23vw;
}

.dashboard__pod__icon--container {
  display: flex;
  justify-content: center;
  margin-left: 0.65vw;
}

.dashboard__pod__body--container {
  display: flex;
  justify-content: center;
  margin-right: 1.26vw;
}

.dashboard__time-check__pod--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  display: flex;
  height: 4.47vw;
  justify-content: center;
  width: 9.01vw;
}

.dashboard__time-check__pod--sub-container {
  align-items: center;
  border-radius: 3.63vw;
  display: flex;
  height: 3.75vw;
  justify-content: center;
  width: 8.28vw;
}

.dashboard__time-check__pod__text {
  color: #085c71;
}

.dashboard__nutrition-check__pod--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  cursor: pointer;
  display: flex;
  height: 4.47vw;
  justify-content: space-between;
  width: 15.2vw;
}

.dashboard__budget-check__pod--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  cursor: pointer;
  display: flex;
  height: 4.47vw;
  justify-content: space-between;
  width: 20.72vw;
}

.dashboard__user-check__pod--container {
  align-items: center;
  background: #ffffff;
  border: 0.052vw solid #dddddd;
  border-radius: 3.63vw;
  cursor: pointer;
  display: flex;
  height: 4.47vw;
  justify-content: center;
  width: 4.47vw;
}

.dashboard__budget-check__pod__text--normal {
  color: #242424;
  font-size: 1.56vw;
  letter-spacing: 0.03em;
}

.dashboard__budget-check__pod__ellipse--container {
  display: flex;
  flex-direction: column;
  margin-right: 1.82vw;
}

.dashboard__budget-check__pod__ellipse--sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard__budget-check__pod__ellipse--icon {
  height: 1.09vw;
  width: 1.09vw;
}

.dashboard__budget-check__pod__ellipse--text {
  color: #242424;
  font-size: 1.25vw;
  margin-left: 0.83vw;
}

.dashboard__nutrition-check__pod--container progress {
  background: #f1f1f1 !important;
  border: none !important;
  border-radius: 3.63vw !important;
  height: 0.75vw !important;
  width: 9.24vw !important;
}

.dashboard__nutrition-check__pod--container
  progress[value]::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 4.5vw;
}

.dashboard__nutrition-check__pod--container progress::-moz-progress-bar {
  background: #5e6163;
  border-radius: 4.5vw;
}

.dashboard__nutrition-check__pod--container progress::-webkit-progress-value {
  background: #5e6163;
  border-radius: 4.5vw;
}

.dashboard__nutrition-check__pod--container progress {
  color: #5e6163;
  border-radius: 4.5vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .dashboard--container {
    left: 3.05vh;
    bottom: 1.7132vh;
    width: 119.7vh;
  }

  .dashboard__pod__icon {
    height: 5.92vh;
    width: 5.92vh;
  }

  .dashboard__time-check__pod--container {
    border: 0.09vh solid #dddddd;
    border-radius: 6.66vh;
    height: 7.96vh;
    width: 16.01vh;
  }

  .dashboard__time-check__pod--sub-container {
    /*border: 0.09vh solid #DDDDDD;*/
    border-radius: 6.66vh;
    height: 6.66vh;
    width: 14.72vh;
  }

  .dashboard__nutrition-check__pod--container {
    border: 0.09vh solid #dddddd;
    border-radius: 6.66vh;
    height: 7.96vh;
    width: 27.87vh;
  }

  .dashboard__budget-check__pod--container {
    border: 0.09vh solid #dddddd;
    border-radius: 6.66vh;
    height: 7.96vh;
    width: 36.85vh;
  }

  .dashboard__user-check__pod--container {
    border-radius: 6.66vh;
    height: 7.96vh;
    width: 7.96vh;
  }

  .dashboard__nutrition-check__pod--container progress {
    border-radius: 6.66vh !important;
    height: 1.38vh !important;
    width: 16.94vh !important;
  }

  .dashboard__pod__icon--container {
    margin-left: 1.2vh;
  }

  .dashboard__pod__body--container {
    margin-right: 2.31vh;
  }

  .dashboard__budget-check__pod__text--normal {
    font-size: 2.77vh;
  }

  .dashboard__budget-check__pod__ellipse--container {
    margin-right: 3.24vh;
  }

  .dashboard__budget-check__pod__ellipse--icon {
    height: 1.94vh;
    width: 1.94vh;
  }

  .dashboard__budget-check__pod__ellipse--text {
    font-size: 2.22vh;
    margin-left: 1.48vh;
  }
}

.card-product--wrapper {
  align-items: center;
  background-color: white;
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  display: flex;
  flex-direction: column;
  height: 17.32vw;
  justify-content: space-between;
  margin: 0.5vw;
  padding: 1vw 0.5vw 0.5vw;
  width: 11.1vw;
}

.card-product__product--title {
  font-size: 1.21vw;
  justify-content: center;
  text-align: center;
}

.card-product__product--icon__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 5.41vw; /*104px*/
  text-align: center;
  width: 5.41vw; /*104px*/
  background-color: #f1f1f1;
  border-radius: 50%;
  justify-content: center;
}

.card-product__product__wic--icon {
  grid-column: 1;
  grid-row: 1;
  position: relative;
  height: 1.75vw; /*33.65px*/
  width: 1.75vw; /*33.65px*/
}

.card-product__product--icon--container {
  display: flex;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 5.41vw; /*104px*/
  justify-content: center;
  align-items: center;
  width: 5.41vw; /*104px*/
}

.card-product__product--icon {
  height: 3.19vw;
  width: 3.39vw;
}

.card-product__product--description {
  font-size: 0.7vw;
  justify-content: center;
  text-align: center;
}

.card-product__button {
  align-items: center;
  background: #20aac0;
  border-radius: 0.41vw;
  cursor: pointer;
  display: flex;
  font-size: 0.8vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 2.42vw;
  justify-content: center;
  width: 10.7vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .card-product--wrapper {
    height: 31.75vh;
    margin: 1vh;
    padding: 2vh 1vh 1vh;
    width: 20.55vh;
  }

  .card-product__product--title {
    font-size: 2.22vh;
  }

  .card-product__product--icon__container {
    height: 9.62vh; /*104px*/
    width: 9.62vh; /*104px*/
  }

  .card-product__product__wic--icon {
    height: 3.11vh; /*33.65px*/
    width: 3.11vh; /*33.65px*/
  }

  .card-product__product--icon--container {
    height: 9.62vh; /*104px*/
    width: 9.62vh; /*104px*/
  }

  .card-product__product--icon {
    height: 5.85vh;
    width: 5.85vh;
  }

  .card-product__product--description {
    font-size: 1.29vh;
  }

  .card-product__button {
    border-radius: 0.74vh;
    font-size: 1.48vh;
    height: 4.44vh;
    width: 19.62vh;
  }
}

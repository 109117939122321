.budget-pop-up--container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.budget-pop-up--wrapper {
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 39.39vw;
  width: 61.61vw;
  border: 0.1vw solid #c4c4c4;
  border-radius: 0.41vw;
  z-index: 0;
}

.budget-pop-up--body {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.budget-pop-up__table {
  justify-content: center;
  display: flex;
}

.budget-pop-up__table tr {
  color: #8c8d8d;
  font-family: RidleyGrotesk Regular, serif;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: normal;
}

.budget-pop-up__table td {
  border-bottom: 0.1vw solid #f1f1f1;
}

.budget-pop-up__table--cell--max-width {
  height: 1.26vw;
  width: 25.25vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .budget-pop-up--wrapper {
    height: 72.22vh;
    width: 112.96vh;
  }

  .budget-pop-up__table tr {
    font-size: 1.75vh;
  }

  .budget-pop-up__table--cell--max-width {
    height: 2.31vh;
    width: 46.29vh;
  }
}

.location-module--wrapper__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 8.48vw auto 2.92vw auto;
}

.location-module--wrapper__close--icon--container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 2;
}

.location-module--form--wrapper__body {
  height: 100%;
  margin: 2.92vw auto 2.92vw auto;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.location-module__in-deep-description {
  color: #535353;
  font-size: 1.21vw;
  text-align: center;
  width: 47.87vw;
}

.location-module__in-deep-description__icon {
  height: 16.16vw;
  width: 16.16vw;
}

.location-module__action-complete__action-message {
  justify-content: center;
  padding-bottom: 10%;
  text-align: center;
  width: 41.66vw;
}

.location-module__form--container {
  border: 0.1vw solid #f1f1f1;
  display: flex;
  height: 30.3vw;
  flex-direction: column;
  justify-content: space-between;
  margin: auto 10.1vw auto 10.1vw;
  padding: 1vw 1vw 1vw 1vw;
}

.location-module__form--container h1 {
  margin-bottom: 0.25vw;
  font-size: 1.25vw;
}

.location-module__form--container p {
  margin-bottom: 0.25vw;
}

.location-module__form__field {
  border-bottom: 0.1vw solid #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1vw;
}

.location-module__form__group {
  display: flex;
  justify-content: space-between;
}

.location-module__form__yes-or-no-element--container {
  display: flex;
  align-items: center;
}

.location-module__form__yes-or-no-element--container > p {
  margin-right: 0.5vw;
}

.location-module__form__yes-or-no-element--container img:not(:last-child) {
  margin-right: 1.5vw;
}

.location-module__form__yes-or-no-element__icon {
  height: 1.41vw;
  width: 1.41vw;
}

.typewriter p {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid transparent; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  animation: typing 3.5s steps(20, end), blink-caret 0.75s step-end 7;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media only screen and (min-aspect-ratio: 16/9) {
  .location-module--wrapper__body {
    margin: 15.55vh auto 5.37vh auto;
  }

  .location-module--form--wrapper__body {
    margin: 5.37vh auto 5.37vh auto;
  }

  .location-module__in-deep-description {
    font-size: 2.22vh;
    width: 87.77vh;
  }

  .location-module__in-deep-description__icon {
    height: 29.62vh;
    width: 29.62vh;
  }

  .location-module__form--container {
    height: 55.55vh;
    margin: auto 18.51vh auto 18.51vh;
    padding: 1.84vh 1.84vh 1.84vh 1.84vh;
  }

  .location-module__form--container h1 {
    font-size: 2.22vh;
    margin-bottom: 0.46vh;
  }

  .location-module__form--container p {
    margin-bottom: 0.46vh;
  }

  .location-module__form__yes-or-no-element--container > p {
    margin-right: 1vh;
  }

  .location-module__form__yes-or-no-element--container img:not(:last-child) {
    margin-right: 3vh;
  }

  .location-module__form__yes-or-no-element__icon {
    height: 2.59vh;
    width: 2.59vh;
  }

  .location-module__action-complete__action-message {
    width: 74.07vh;
  }
}

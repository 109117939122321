.health-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ledger-slide--container .health-status {
  margin-top: 3.5vw;
}

.health-status .health-status--title {
  font-weight: 600;
  margin-bottom: 1vw;
}

@media only screen and (min-aspect-ratio: 16/9) {
  .health-status .health-status--title {
    margin-bottom: 2vh;
  }

  .ledger-slide--container .health-status {
    margin-top: 7vh;
  }
}
